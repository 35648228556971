.topbar {
    width: 100%;
    padding: .8rem;
    background-color: #005f59;
    position: relative;

}

.topbar .topbar-content {
    display: flex;
    justify-content: space-around;
    text-align: center;
    color: white;
}

.topbar .topbar-content p {
    padding: 0 1rem;
    font-size: 14px;
}

.topbar .bar-box1 {
    width: 27rem;
    border-right: 1px solid rgba(22, 116, 27, 0.616);
}

.topbar .bar-box2 {
    width: 15rem;
    border-right: 1px solid rgba(22, 116, 27, 0.616);
}

.topbar span {
    color: rgba(36, 173, 24, 0.616);
    font-size: 16px;
    font-weight: bold;
}

@media screen and (max-width: 940px) {
    .topbar-hide {
        display: none;
    }
}