@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@300&display=swap');

.treatment {
    width: 100%;
    height: 70vh;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: #0a0a0a69;
  }
  
  .treatment:before {
    content: '';
    position: absolute;
    background: url('../../assets//menu-bg-treatment.jpg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

.treatment .treatment-content {
    display: block;
    position: absolute;
    bottom: 15%;
    padding: 1rem;
}

.treatment h1 {
    color: white;
    font-size: 3rem;
}

.treatment-wrap .treatment-title h1 {
    font-size: 1.5rem;
    text-align: left;
    font-family: 'Montserrat', sans-serif;
    color: var(--primary-color);
}



/* MEDIA QUERIES - HERO */

@media screen and (max-width:940px) {

.treatment h1 {
    font-size: 2.5rem;
    text-align: center;
}

}

